import { Link } from 'gatsby';
import React from 'react';
import { Typography } from '../Typography';

interface IPostExcerptProps {
  title: string;
  slug: string;
  date: Date;
  body: string | JSX.Element | JSX.Element[];
}

export const PostExcerpt = (props: IPostExcerptProps) => {
  const { title, date, body, slug } = props;

  return (
    <div>
      <article>
        <Link to={slug}>
          <div>{title}</div>
        </Link>
        <div>
          {date.toLocaleString([], {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
          })}
        </div>
        <Typography>
          {body}
        </Typography>
      </article>
    </div>
  );
};
